import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { FrontPageComponent } from './front-page/front-page.component';

const routes: Routes = [
  {
    path: '',
    component: FrontPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'research',
    loadChildren: () => import('./research/research.module').then((m) => m.ResearchModule),
  },
  {
    path: 'education',
    loadChildren: () => import('./education/education.module').then((m) => m.EducationModule),
  },
  {
    path: 'health-services',
    loadChildren: () => import('./health-services/health-services.module').then((m) => m.HealthServicesModule),
  },

  {
    path: 'background',
    loadChildren: () => import('./background/background.module').then((m) => m.BackgroundModule),
  },

  {
    path: 'contact-us',
    component: ContactComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
