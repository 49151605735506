<app-container headerTitle="Contact">
  <p>Information about the theory, model, educational module and pedagogical health service:</p>
  <p>Dr.polit Kristin Heggdal</p>
  <p>
    <a href="mailto:post@bodyknowledging.com">post@bodyknowledging.com</a>
  </p>
  <p>Information about professional experience on applying the model in practice:</p>

  <p>Contact</p>
  <p>Advanced Nurse Specialist: Janne Merete Hernes Brateng</p>
  <p>
    <a href="mailto:Janne.Hernes-Brateng@gjovik.kommune.no">Janne.Hernes-Brateng@gjovik.kommune.no</a>
  </p>
  <p>
    Or<br />
    Physiotherapist: <a href="mailto:Marianne.Martinsen@gjovik.kommune.no">Marianne.Martinsen@gjovik.kommune.no</a>
  </p>
  <p>
    Or<br />
    Occupational Therapist:
    <a href="mailto:Anita.Fjellheim@sykehuset-innlandet.no">Anita.Fjellheim@sykehuset-innlandet.no</a>
  </p>
</app-container>
