export const BOX_PREVIEWS = [
  {
    title: 'Research',
    content: 'Research on and development of the Bodyknowledging concept has progressed since 2000 and is continued paralell to practice.',
    link: '/research',
  },
  {
    title: 'Education',
    content: 'The educational module on Bodyknowledging is a part-time study for health care personnel who want to learn more about how...',
    link: '/education',
  },
  {
    title: 'Health Services',
    content:
      'The pedagogical health service of Bodyknowledging implies that patients and health professionals work together to strenghten health and well-being.',
    link: '/health-services',
  },
];
