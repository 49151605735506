export const MENU_DATA = [
  {
    id: 1,
    header: 'Research',
    routerLink: ['research'],
    children: [
      {
        header: 'Publications',
        routerLink: ['research', 'publications'],
      },
      {
        header: 'Projects',
        routerLink: ['research', 'projects'],
      },
      {
        header: 'Presentations',
        routerLink: ['research', 'presentations'],
      },
      {
        header: 'Information',
        routerLink: ['research', 'information'],
      },
    ],
  },
  {
    id: 2,
    header: 'Education',
    routerLink: ['education'],
    children: [
      {
        header: 'Education',
        routerLink: ['education', 'education'],
      },
      {
        header: 'Information',
        routerLink: ['education', 'information'],
      },
    ],
  },
  {
    id: 3,
    header: 'Health Services',
    routerLink: ['health-services'],
    children: [
      {
        header: 'Users',
        routerLink: ['health-services', 'users'],
      },
      {
        header: 'Health Care Professionals',
        routerLink: ['health-services', 'health-personnel'],
      },
      {
        header: 'Information',
        routerLink: ['health-services', 'information'],
      },
    ],
  },
  {
    id: 4,
    header: 'Background',
    routerLink: ['background'],
    children: [
      {
        header: 'What is Bodyknowledging',
        routerLink: ['background', 'what-is-bodyknowledging'],
      },
    ],
  },
  {
    id: 6,
    header: 'Contact Us',
    routerLink: ['contact-us'],
    children: [],
  },
];
