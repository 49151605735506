<div class="about">
  <div class="about-content">
    <h1>BODYKNOWLEDGING – a new concept and approach for strengthening people’s capacity to be in charge of their health</h1>
    <div class="slide-read-more">
      <h4 style="margin: 0; padding: 5px">
        <a style="color: #fff" routerLink="/background/what-is-bodyknowledging"
          >Read more<span style="padding-left: 5px" class="glyphicon glyphicon-forward"></span
        ></a>
      </h4>
    </div>
  </div>
</div>
<div class="box-container">
  <span class="filler"></span>
  <app-preview-box
    *ngFor="let preview of previews"
    [title]="preview.title"
    [content]="preview.content"
    [link]="preview.link"
  ></app-preview-box>
  <span class="filler"></span>
</div>
